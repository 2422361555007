export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "sentiment-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmung erkennen"])},
        "sentiment-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke, wie die KI die Stimmung in einem Text bestimmt!"])},
        "inspection-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenstände erkennen"])},
        "inspection-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde heraus, woran die KI Gegenstände auf Bildern erkennt!"])},
        "country-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guess the Country"])},
        "country-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannst du besser als die KI zwischen Tel-Aviv und Berlin unterscheiden?"])}
      },
      "en": {
        "sentiment-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment Analysis"])},
        "sentiment-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover how the AI determines a text's sentiment!"])},
        "inspection-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Detection"])},
        "inspection-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover how the AI detects objects in an image!"])},
        "country-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guess the Country"])},
        "country-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you beat the AI at distinguishing Tel-Aviv from Berlin?"])}
      }
    }
  })
}
