export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterfrage die KI und entdecke Erklärbare Künstliche Intelligenz"])},
        "info1headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der XAI-Demonstrator"])},
        "info1paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine KI, die sich dir gegenüber wie ein Team-Mitglied erklärt? Der XAI-Demonstrator zeigt, wie das geht."])},
        "info1paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhand leicht zugänglicher Beispiele veranschaulicht die App die Möglichkeiten von Explainable AI (XAI). Live und interaktiv erzeugt sie Erklärungen mit modernen Methoden direkt aus der Forschung."])},
        "info1paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit wird die Vision einer Künstlichen Intelligenz, die nicht länger eine Black Box ist, sondern von ihren Nutzerinnen und Nutzern verstanden und hinterfragt werden kann, Realität."])},
        "infoLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse geweckt? Besuche unsere Website!"])}
      },
      "en": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrutinize the AI and discover Explainable Artificial Intelligence"])},
        "info1headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The XAI Demonstrator"])},
        "info1paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An AI that acts like a team member and explains its decisions and actions? The XAI Demonstrator shows that it's possible."])},
        "info1paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using accessible examples, the app demonstrates the potential of Explainable AI (XAI). All explanations are generated in real time using state-of-the-art XAI methods."])},
        "info1paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The XAI Demonstrator brings the vision of an AI that is no longer a black box to reality, allowing its users to fully comprehend and challenge its decisions."])},
        "infoLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to learn more? (in German)"])}
      }
    }
  })
}
